import core from "@/core";
import { PaymentModel } from "@/models/estimate/estimate.model";

class EstimatePaymentService {
  protected readonly url = "/api/v1/estimate";
  protected readonly itemKey = "payment";

  create(estimateId: number, params: any) {
    const url = `${this.url}/${estimateId}/payment`;
    const strParams = JSON.stringify(params);
    return new Promise((resolve, reject) => {
      core.http
        .postJson(`${url}`, strParams)
        .then((data) => {
          if (typeof data === "object") {
            const item = data[this.itemKey];
            if (item != null) {
              resolve(item);
            } else {
              reject({
                message: "not found item",
              });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  update(estimateId: number, paymentId: number, params: any) {
    const url = `${this.url}/${estimateId}/payment/${paymentId}`;
    const strParams = JSON.stringify(params);
    return new Promise((resolve, reject) => {
      core.http
        .putJson(`${url}`, strParams)
        .then((data) => {
          if (typeof data === "object") {
            const item = data[this.itemKey];
            if (item != null) {
              resolve(item);
            } else {
              reject({
                message: "not found item",
              });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  getList(estimateId: number) {
    const url = `${this.url}/${estimateId}/payment`;
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`${url}`)
        .then((data) => {
          if (typeof data === "object") {
            const item = data[this.itemKey + "List"];
            if (item != null) {
              resolve(item);
            } else {
              reject({
                message: "not found item",
              });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  delete(estimateId: number, paymentId: number) {
    const url = `${this.url}/${estimateId}/payment/${paymentId}`;
    return new Promise((resolve, reject) => {
      core.http
        .delete(`${url}`, null)
        .then((data) => {
          resolve(data);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  typeToText(item: PaymentModel) {
    const type = item.type;
    if (type === "CACHE") {
      return "현금";
    } else if (type === "CACHE_RECEIPT") {
      return "현금(영수증)";
    } else if (type === "CARD") {
      return "카드";
    } else if (type === "COUPON") {
      return "쿠폰";
    } else if (type === "DISCOUNT") {
      return "할인";
    }
    return "";
  }
}

export default new EstimatePaymentService();
