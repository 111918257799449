
import Vue from "vue";
import store from "@/store";
import AppBar from "@/components/core/AppBar.vue";
import AlertModal from "@/modals/core/AlertModal.vue";
import { RouteMeta } from "@/router";
import core from "@/core";
import { cloneDeep } from "lodash";
import AppBottomBar from "@/components/core/AppBottomBar.vue";
import UpdateEvent from "@/models";
import { UpdateEventType } from "@/types";
import { TYPE } from "vue-toastification";

export default Vue.extend({
  name: "App",
  components: { AppBottomBar, AlertModal, AppBar },
  data: () => ({
    transitionName: "forward",
    app: store.state.app,
    prop: {
      menuType: "",
      subMenu: null as any,
      closeNavigation: false,
    },
    redirect: {
      path: null as string | null,
      interval: null as number | null,
    },
    bottomNav: "",
    updateEvent: null as UpdateEvent | null,
  }),
  mounted() {
    //console.log("app mounted");
    if (process.env.NODE_ENV === "production") {
      if (location.hostname.indexOf("sigong.scc20.co.kr") > -1) {
        location.href = location.href.replace("sigong.scc20.co.kr", "sigongstore.com");
      }
    }
    
    (window as any).updateMobileSystemProperties = this.updateMobileSystemProperties;
    (window as any).updateMobileSettings = this.updateMobileSettings;
    (window as any).updatePaymentSmsSettings = this.updatePaymentSmsSettings;
    (window as any).socialLogin = this.socialLogin;
    (window as any).mobileRedirectPath = this.mobileRedirectPath;
    (window as any).showModal = this.showModal;
    (window as any).showToast = this.showToast;
    (window as any).chnageMeta = this.changeMeta;
    // (window as any).occurNotification = this.occurNotification;
    // (window as any).changedUserCompany = this.changedUserCompany;

    const windowResize = this.windowResize;
    window.addEventListener("resize", () => {
      windowResize();
    });
    windowResize();

    const platform = core.utils.platform();
    if (platform === "android" || platform === "ios") {
      this.app.isMobile = true;
    }
    this.app.isApp = core.mobile.isApp();

    (this as any).$eventBus.$on("updateEvent", (event: UpdateEvent) => {
      console.log("call updateEvent : ", event);
      this.updateEvent = event;
    });
  },
  watch: {
    bottomNav(bottomNav) {
      console.log("bottomNav : ", bottomNav);
    },
    $route(to, from) {
      if (to.params["stackKey-dir"] === "forward") {
        this.transitionName = "forward";
      } else {
        this.transitionName = "back";
        const event = this.updateEvent;
        if (event != null) {
          this.updateEvent = null;
          if (event.result == UpdateEventType.PAGE) {
            const item = event.item;

            const params = {} as any;
            if (item.path) {
              params.path = item.path;
            }
            if (item.query) {
              params.query = item.query;
            }

            if (to.path !== params.path) {
              // console.log("from page : ", from.path);
              // console.log("to page : ", to.path);
              // console.log("move page : ", item.path);
              this.$router.push(params);
            }
          } else {
            console.log("unknown event type : ", event);
          }
        }
      }
      const meta = to.meta as RouteMeta;
      // console.log("meta : ", meta);
      this.changeMeta(meta);
      this.app.path = this.$route.path;

      this.windowResize();
    },
    "redirect.path"(path) {
      const redirect = this.redirect;
      if (redirect.path != null && redirect.interval == null) {
        const auth = this.$store.state.auth;
        const router = this.$router;
        const vm = this as any;

        redirect.interval = setInterval(() => {
          if (auth.user != null) {
            const path = redirect.path as string;
            redirect.path = null;
            // console.log("redirect : ", path);
            try {
              router.push({
                path: path,
              });
            } catch (e) {
              console.log(e);
            }
          }

          if (redirect.path == null || vm._isDestroyed) {
            clearInterval(redirect.interval as number);
            redirect.interval = null;
          }
        }, 200);
      }
    },
    "app.company"(company) {
      if (company != null) {
        console.log("company : ", company);
      }
    },
  },
  methods: {
    changeMeta(meta: RouteMeta) {
      this.prop.menuType = meta.menuType;
      this.prop.subMenu = meta.subMenu;
      this.prop.closeNavigation = true;
    },
    windowResize() {
      // console.log(`resize width '${window.innerWidth}', '${window.innerHeight}'`);
      // console.log(`resize width '${window.outerWidth}', '${window.outerHeight}'`);
      this.app.size = {
        width: window.innerWidth,
        height: window.innerHeight,
      };
      // document.body.style.minHeight = this.app.size.height + "px";
      document.documentElement.style.setProperty("--vh", `${this.app.size.height * 0.01}px`);
    },
    // occurNotification: function (item: any) {
    //   const path = this.$route.path;
    //
    //   const notificationList = store.state.data.notificationList;
    //   let exists = false;
    //   let existsIndex = 0;
    //   for (let i = 0; i < notificationList.length; i++) {
    //     const _item = notificationList[i];
    //     if (_item.id === item.id) {
    //       exists = true;
    //       existsIndex = i;
    //       break;
    //     }
    //   }
    //   if (!exists) {
    //     notificationList.push(item);
    //     console.log("occur notification : ", item);
    //   } else if (item.confirmedAt != null) {
    //     notificationList.splice(existsIndex, 1);
    //     console.log("list : ", notificationList);
    //   }
    //
    //   if (path.startsWith("/main")) {
    //     //console.log("item : ", item);
    //   } else {
    //     this.showToast(item.content);
    //   }
    // },
    showModal: function (params) {
      return core.alert.show(params);
    },
    // showToast: function (content: string) {
    //   this.$toast.info(content);
    // },
    showToast: function (content: string) {
      core.toast.show({
        type: TYPE.INFO,
        content: content,
      });
    },
    mobileRedirectPath: function (path: string) {
      //console.log("mobileRedirectPath : ", path);
      if (path != null && path.length > 0) {
        this.redirect.path = path;
      }
    },
    updateMobileSystemProperties: function (strSystemProperties: string) {
      const systemProperties = JSON.parse(strSystemProperties);

      this.$store.dispatch("auth/updateMobileSystemProperties", systemProperties);
    },
    updateMobileSettings: function (strSettings: string) {
      const mobileSettings = JSON.parse(strSettings);

      this.$store.dispatch("auth/updateMobileSettings", mobileSettings);
    },
    updatePaymentSmsSettings: function (strSettings: string) {
      const settings = JSON.parse(strSettings);

      this.$store.dispatch("auth/updatePaymentSmsSettings", settings);
    },
    socialLogin: function (strParam: string, addProvider: boolean | null) {
      //console.log(strParam);
      if (addProvider == null) addProvider = false;
      const token = JSON.parse(strParam);
      core.loader.show("로그인 중...");
      const params = {
        registrationId: "kakao",
        accessToken: token.accessToken,
        rememberMe: true,
      } as any;

      if (addProvider) {
        params.addProvider = addProvider;
      }

      this.$store
        .dispatch("auth/socialLogin", params)
        .then(async (data) => {
          if (data.result) {
            console.log("call reload");
            window.location.reload();
          } else {
            //console.log(data);
            if (data.type === "addProvider") {
              core.loader.hide();
              const result = await core.alert.show({
                title: "확인",
                body: "이미 가입된 계정이 존재합니다.<br>소셜 로그인 정보를 추가하시겠습니까?",
                showCancelButton: true,
                cancelButtonText: "아니오",
                confirmButtonText: "예",
              });

              if (result === "confirm") {
                this.socialLogin(strParam, true);
              } else {
                await core.alert.show({
                  title: "알림",
                  body: "가입된 계정 정보로 로그인 해주세요",
                });
              }
            } else {
              core.loader.hide();

              // 가입되지 않은 계정 회원가입 페이지로 이동
              const params = cloneDeep(data.socialUser) as any;
              params.token = data.token;

              const query = this.$route.query;
              console.log("query : ", query);
              const keys = Object.keys(query);
              for (const key of keys) {
                if (!params[key]) {
                  params[key] = query[key];
                }
              }
              //console.log("params : ", params);
              await this.$router.push({
                path: "/register",
                query: params,
              });
            }
          }
        })
        .catch((reason) => {
          core.loader.hide();
        });
    },
  },
});
