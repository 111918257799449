
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import core from "@/core";
import CustomerService from "@/services/customer/customer.service";
import { EstimateModel } from "@/models/estimate/estimate.model";

export default mixins(MixinsPageForm).extend({
  name: "Home",
  components: {},
  data: () => ({
    estimate: null as EstimateModel | null,
    visible: {
      estimate: true,
      payment: false,
    },
  }),
  mounted() {
    this.$nextTick(async () => {
      try {
        const estimate = (this.estimate = (await CustomerService.getEstimate()) as EstimateModel);
        this.visible.payment = estimate.totalPrice > 0;
        // console.log("estimate : ", estimate);
      } catch (e) {
        console.log(e);
      }
    });
  },
  methods: {
    getPhoneHtml() {
      if (this.estimate != null && this.estimate.ownerCompany != null) {
        const phone = core.utils.format.hyphenPhone(this.estimate.ownerCompany.phone);
        return `<a href="tel:${phone}">${phone}</a>`;
      }
      return "";
    },
    async logout() {
      const result = await core.alert.show({
        title: "확인",
        body: "현재 인증된 정보가 해제됩니다.<br>계속 진행하시겠습니까?",
        showCancelButton: true,
      });
      if (result === "confirm") {
        await this.$router.push("/logout");
      }
    },
  },
});
