
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import CustomerService from "@/services/customer/customer.service";
import EstimatePrintComponent from "@/components/estimate/PrintComponent.vue";
import { EstimateModel } from "@/models/estimate/estimate.model";
import { CalendarEventModel } from "@/models/calendar/calendar-event.model";
import core from "@/core";
import EstimateDownloadModal from "@/modals/estimate/DownloadModal.vue";
import UpdateEvent from "@/models";

export default mixins(MixinsPageForm).extend({
  name: "Estimate",
  components: { EstimateDownloadModal, EstimatePrintComponent },
  data: () => ({
    estimate: null as EstimateModel | null,
    calendarEventList: [] as CalendarEventModel[],
    download: {
      index: 0,
      uriList: [] as string[],
    },
    modal: {
      download: {
        visible: false,
        params: {
          uriList: [] as string[],
        },
        updateEvent: null as UpdateEvent | null,
      },
    },
    view: {
      remark: "",
    },
  }),
  mounted() {
    this.$nextTick(async () => {
      try {
        const estimate = (await CustomerService.getEstimate()) as EstimateModel;
        // console.log("estimate : ", estimate);
        this.estimate = estimate;

        if (this.isBlank(estimate.remark)) {
          this.view.remark = "";
        } else {
          this.view.remark = core.utils.format.textToHtml(estimate.remark);
        }
        const eventList = (this.calendarEventList =
          (await CustomerService.getCalendarEventList()) as CalendarEventModel[]);
        //console.log("eventList : ", eventList);
      } catch (e) {
        console.log(e);
      }
    });
  },
  methods: {
    async exportEstimate() {
      // 이미지 or PDF 파일 다운로드
      try {
        core.loader.show();
        const result = (await CustomerService.exportEstimate()) as any;
        if (result.downloadUriList) {
          this.download.index = 0;
          this.download.uriList = result.downloadUriList;
        }
      } catch (e) {
        console.log(e);
      }

      if (this.download.uriList.length === 0) {
        core.alert.show({
          title: "알림",
          body: "견적서 내보내기 파일 생성 실패",
        });
      } else {
        const modal = this.modal.download;
        modal.params.uriList = this.download.uriList;
        modal.visible = true;
      }
      core.loader.hide();
    },
    movePaymentPage() {
      this.$router.push({
        path: `/estimate/payment`,
      });
    },
    contractToHtml(contract) {
      if (contract === "WAIT") return "<span class='red--text'>대기</span>";
      else if (contract === "COMPLETE") return "<span class='green--text'>완료</span>";
      else if (contract === "CANCEL") return "<span class='black--text'>취소</span>";
      return "알 수 없음";
    },
    calendarEventDateToText(item: CalendarEventModel) {
      let yearFormat = "YYYY년 ";
      const moment = core.date.instance();
      const startMoment = core.date.instance(item.startAt);
      const endMoment = core.date.instance(item.endAt);

      if (
        moment.format("YYYY") === startMoment.format("YYYY") &&
        moment.format("YYYY") === endMoment.format("YYYY")
      ) {
        yearFormat = "";
      }

      let strDate = "";
      // console.log(startMoment.format("YYYY-MM-DD"));
      // console.log(endMoment.format("YYYY-MM-DD"));
      if (startMoment.format("YYYY-MM-DD") == endMoment.format("YYYY-MM-DD")) {
        if (item.allDay) {
          strDate = startMoment.format(yearFormat + "MM월 DD일(ddd)");
        } else {
          strDate =
            startMoment.format(yearFormat + "MM월 DD일(ddd) a hh:mm") +
            " ~ " +
            endMoment.format("a hh:mm");
        }
      } else {
        if (item.allDay) {
          strDate =
            startMoment.format(yearFormat + "MM월 DD일(ddd)") +
            " ~ " +
            endMoment.format(yearFormat + "MM월 DD일(ddd)");
        } else {
          strDate =
            startMoment.format(yearFormat + "MM월 DD일(ddd) a hh:mm") +
            " ~ " +
            endMoment.format(yearFormat + "MM월 DD일(ddd) a hh:mm");
        }
      }
      return strDate;
    },
    calendarEventEstimateDetailToText(item: CalendarEventModel) {
      let text = "";
      if (item.estimateDetailList != null) {
        item.estimateDetailList.forEach((detail) => {
          //console.log("detail : ", detail);
          text += detail.description + ",";
        });
        if (text.length > 0) {
          text = text.substr(0, text.length - 1);
        }
      }
      return text;
    },
  },
});
