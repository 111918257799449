
import mixins from "vue-typed-mixins";
import store from "@/store";

export default mixins().extend({
  name: "AppBottomBar",
  data: () => ({
    bottomNav: "",
    companyId: 0,
    menuList: [],
    app: store.state.app,
    auth: store.state.auth,
    menuItems: {
      selected: null as any,
      list: [] as any,
    },
  }),
  computed: {
    isUserRole() {
      return this.$store.getters["auth/isUserRole"];
    },
  },
  created() {
    //this.changedCompany();
  },
  mounted() {
    this.$nextTick(() => {
      this.bottomNav = "home";
    });
  },
  watch: {
    bottomNav(bottomNav) {
      this.$emit("update:bottomNav", bottomNav);
    },
  },
  methods: {
    goBack() {
      this.$router.back();
    },
  },
});
