
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import ConstructionStatusComponent from "@/components/constructino-status/ConstructionStatusComponent.vue";

export default mixins(MixinsPageForm).extend({
  name: "ConstructionStatus",
  components: { ConstructionStatusComponent },
  data: () => ({
    visible: {
      estimate: true,
      specialPrice: false,
    },
  }),
  mounted() {
    this.$nextTick(async () => {});
  },
  methods: {},
});
