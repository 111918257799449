
import mixins from "vue-typed-mixins";
import store from "@/store";
import MixinsPageForm from "@/mixins/page-form";
import core from "@/core";
import CustomerService from "@/services/customer/customer.service";

export default mixins(MixinsPageForm).extend({
  name: "Login",

  data: () => ({
    app: store.state.app,
    processing: false,
    form: {
      authCode: "",
      customerPhone: "",
      rememberMe: true,
    },
    formConvertField: {
      username: "user_customerPhone",
      password: "user_authCode",
    },
  }),

  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      const query = this.$route.query;
      let value = query.v as string;
      if (core.utils.validate.isNotBlank(value)) {
        try {
          value = atob(value);
        } catch (e) {
          console.log(e);
        }

        const array = value.split(",");
        if (array.length === 2) {
          const form = this.form;
          form.customerPhone = array[0];
          form.authCode = array[1];

          let sameUser = false;
          const user = await this.$store.getters["auth/user"]();
          if (user && user.id !== 0) {
            try {
              const estimate = (await CustomerService.getEstimate()) as any;
              if (estimate.customerPhone == array[0] && estimate.authCode == array[1]) {
                sameUser = true;
              }
            } catch (e) {
              console.log(e);
            }
          }

          if (sameUser) {
            if (this.isNotBlank(query.page)) {
              this.replaceState();
              await this.$router.push(query.page as string);
            } else if (this.isNotBlank(query.p)) {
              this.replaceState();
              await this.$router.push(query.p as string);
            } else {
              await this.$router.push("/");
            }
          } else {
            // 로그아웃 처리
            if (this.$store.state.auth.user != null) {
              await this.$store.dispatch("auth/logout");
            }

            await this.submit(true);
          }
        }
      }
    });
  },
  watch: {
    "form.customerPhone"(val) {
      this.form.customerPhone = this.phoneFormat(val);
    },
  },
  methods: {
    async submit(ignoreValidate) {
      const query = this.$route.query;
      if (ignoreValidate == null) ignoreValidate = false;
      let isValid = true;
      if (!ignoreValidate) {
        isValid = await this.validate();
      }

      if (isValid) {
        this.processing = true;
        try {
          const user = await this.$store.dispatch("auth/login", this.form);

          if (this.isNotBlank(query.page)) {
            this.replaceState();
            await this.$router.push(query.page as string);
          } else if (this.isNotBlank(query.p)) {
            this.replaceState();
            await this.$router.push(query.p as string);
          } else {
            await this.$router.push("/");
          }
        } catch (e) {
          this.errorSubmit(e);
        }
        this.processing = false;
      }
    },
  },
});
