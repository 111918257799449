import core from "@/core";
import ColorItems from "@/data/color-items";

class CalendarUtils {
  readonly colorItems = [] as any;
  readonly colorHexItems = {} as any;

  constructor() {
    ColorItems.forEach((item) => {
      this.colorItems.push(item);
    });
    this.colorItems.push({ color: "grey", hex: "#9E9E9E", text: "그레이" });
    this.colorItems.push({ color: "grey darken-1", hex: "#757575", text: "그레이" });

    this.colorItems.forEach((item: any) => {
      this.colorHexItems[item.color] = item.hex;
    });
  }

  isStartAtBeforeToday(schedule: any) {
    const moment = core.date.instance();
    const endAtMoment = core.date.instance(schedule.startAt);
    return moment.isAfter(endAtMoment);
  }

  isEndAtBeforeToday(schedule: any) {
    const moment = core.date.instance();
    const endAtMoment = core.date.instance(schedule.endAt);
    return moment.isAfter(endAtMoment);
  }

  colorToHex(color: string) {
    return this.colorHexItems[color];
  }

  eventDateToString(calendarEvent) {
    // console.log(`${schedule.startAt}, ${schedule.endAt}`);
    const startMoment = core.date.instance(calendarEvent.startAt);
    const endMoment = core.date.instance(calendarEvent.endAt);
    let strDate = "";
    // console.log(startMoment.format("YYYY-MM-DD"));
    // console.log(endMoment.format("YYYY-MM-DD"));
    if (startMoment.format("YYYY-MM-DD") == endMoment.format("YYYY-MM-DD")) {
      if (calendarEvent.allDay) {
        strDate = startMoment.format("YYYY년 MM월 DD일(ddd)");
      } else {
        strDate =
          startMoment.format("YYYY년 MM월 DD일(ddd) a hh:mm") + " ~ " + endMoment.format("a hh:mm");
      }
    } else {
      if (calendarEvent.allDay) {
        strDate =
          startMoment.format("YYYY년 MM월 DD일(ddd)") +
          " ~ " +
          endMoment.format("YYYY년 MM월 DD일(ddd)");
      } else {
        strDate =
          startMoment.format("YYYY년 MM월 DD일(ddd) a hh:mm") +
          " ~ " +
          endMoment.format("YYYY년 MM월 DD일(ddd) a hh:mm");
      }
    }
    return strDate;
  }

  typeToString(type) {
    if (type === "user") {
      return "개인";
    } else if (type === "company") {
      return "업체";
    } else if (type === "company_expected_date") {
      return "시공예정일";
    }
    return "알 수 없음";
  }
}

export default new CalendarUtils();
