
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import CustomerService from "@/services/customer/customer.service";
import EstimatePrintComponent from "@/components/estimate/PrintComponent.vue";
import { EstimateModel } from "@/models/estimate/estimate.model";
import core from "@/core";

export default mixins(MixinsPageForm).extend({
  name: "Estimate",
  components: { EstimatePrintComponent },
  data: () => ({
    first: true,
    estimate: null as EstimateModel | null,
  }),
  mounted() {
    this.$nextTick(async () => {
      try {
        const estimate = (await CustomerService.getEstimate()) as EstimateModel;
        // console.log("estimate : ", estimate);
        this.estimate = estimate;
      } catch (e) {
        console.log(e);
      }
      document.documentElement.classList.add("print");
      document.documentElement.classList.add(this.app.isMobile ? "mobile" : "pc");
    });
  },
  beforeRouteLeave(to, from, next) {
    document.documentElement.classList.remove("print");
    document.documentElement.classList.remove(this.app.isMobile ? "mobile" : "pc");
    next();
  },
  updated() {
    if (this.first) {
      this.first = false;
      setTimeout(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        if (userAgent.indexOf("android") > -1 && userAgent.indexOf("scc") > -1) {
          core.mobile.call({ cmd: "print" });
        } else if (userAgent.indexOf("msie") > -1 || userAgent.indexOf("trident") > -1) {
          document.execCommand("print", true);
        } else {
          window.print();
        }
      }, 500);
    }
  },
  methods: {
    print() {
      console.log("print");
    },
  },
});
