
import mixins from "vue-typed-mixins";
import MixinsModal from "@/mixins/modal";
import { UpdateEventType } from "@/types";
import core from "@/core";

export default mixins(MixinsModal).extend({
  name: "EstimateDownloadModal",
  data: () => ({
    fileList: [] as any,
  }),
  mounted() {
    // 페이지 로딩 후 호출
    this.$nextTick(async () => {
      if (this.properties.params.uriList != null) {
        this.properties.params.uriList.forEach((uri) => {
          const baseName = core.utils.file.getBaseName(uri);
          const ext = core.utils.file.getExtension(uri).toLowerCase();
          let title = ext.toUpperCase();
          let filename = "시공 견적서_" + core.date.instance().format("YYYYMMDD") + "." + ext;
          if (ext != "pdf") {
            const number = baseName.split("_")[1];
            filename = `시공 견적서_${core.date
              .instance()
              .format("YYYYMMDD")}_${number}p.${ext}`;
            title = `이미지 ${number}p`;
          }
          this.fileList.push({
            uri: uri,
            ext: ext,
            filename: filename,
            title: title,
          });
        });
      } else {
        this.notFound();
      }
    });
  },
  methods: {
    download(file) {
      core.http.downloadFile(file.uri, file.filename);
    },
    async submit() {
      this.close(UpdateEventType.CONFIRM);
    },
  },
});
