import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import { AppState, RootState } from "@/types";
import { RouterBeforeTask } from "@/plugins/vue-page-stack/router-before-task";
import Constant from "@/store/constant";
import core from "@/core";
import { Subscribe } from "@/core/core-web-socket";

const state: AppState = {
  name: Constant.appName,
  size: { width: 0, height: 0 },
  routerBeforeTask: new RouterBeforeTask(),
  modal: {
    alert: {
      visible: false,
      title: "알림",
      body: "내용",
      showCancelButton: false,
      cancelButtonText: "취소",
      confirmButtonText: "확인",
      promise: {
        resolve: null,
        reject: null,
      },
      allowBackCloseEvent: true,
    },
  },
  path: "",
  selectedCalendarId: "all",
  printClass: null,
  isApp: false,
  isMobile: false,
  webSocketConnected: false,
  data: {
    ready: false,
  },
};

export const getters: GetterTree<AppState, RootState> = {};

export const actions: ActionTree<AppState, RootState> = {
  addPrint({ commit }, deviceType: string) {
    if (state.printClass == null) {
      document.documentElement.classList.add("print");
      document.documentElement.classList.add(deviceType);
      state.printClass = deviceType;
    }
  },
  clearPrint({ commit }) {
    if (state.printClass) {
      document.documentElement.classList.remove("print");
      document.documentElement.classList.remove("mobile");
      document.documentElement.classList.remove("pc");
      state.printClass = null;
    }
  },
  webSocketConnected({ commit }, data) {
    commit("webSocketConnected", data);
  },
};

const mutations: MutationTree<AppState> = {
  async webSocketConnected(theState, data) {
    const connected = data.connected;
    const subscribeList: Subscribe[] | null = data.subscribeList;

    const preWebSocketConnected = theState.webSocketConnected;
    theState.webSocketConnected = connected;

    // if (preWebSocketConnected != connected) {
    //   //console.log("change web-socket connected : ", connected);
    //
    //   if (connected) {
    //     // console.log("subscribeList : ", subscribeList);
    //
    //     if (subscribeList != null) {
    //       subscribeList.forEach((subscribe) => {
    //         if (subscribe.type != null) {
    //           core.webSocket.subscribe(subscribe.type, subscribe.item);
    //         }
    //       });
    //     }
    //     // theState.data.ready = true;
    //   }
    // }
  },
};

export const app: Module<AppState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
