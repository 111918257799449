import korea from "vee-validate/dist/locale/ko.json";
import core from "@/core";

class Constant {
  appName = "시공";
  validate = {
    language: {
      korea: korea,
    },
  };
  kakao = {
    restApiKey: "a53ef23c5edaaa2c2c7ed536f6477d47",
  };
  url = "https://customer.sigongstore.com";
  viewerOptions = {
    inline: false,
    button: true,
    navbar: true,
    title: false,
    toolbar: {
      zoomIn: true,
      zoomOut: true,
      oneToOne: true,
      reset: true,
      rotateLeft: true,
      rotateRight: true,
      flipHorizontal: true,
      flipVertical: true,
      prev: true,
      play: true,
      next: true,
      download: async (e) => {
        const viewer = document.querySelector(
          ".viewer-container.viewer-in .viewer-canvas img"
        ) as any;
        if (viewer) {
          try {
            await core.http.downloadFile(viewer.src);
          } catch (e) {
            console.log(e);
          }
        }
      },
    },
    tooltip: true,
    movable: true,
    zoomable: true,
    rotatable: false,
    scalable: false,
    transition: true,
    fullscreen: true,
    keyboard: true,
  };
}

export default new Constant();
