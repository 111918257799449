import qs from "qs";
import core from "@/core";
import { UserModel } from "@/models/user/user.model";
import store from "@/store";
import { CompanyRole } from "@/types";

class UserService {
  login(params) {
    const strParam = qs.stringify(params);
    return new Promise((resolve, reject) => {
      core.http
        .post(`/api/v1/customer/login`, strParam)
        .then((data) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  logout() {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/v1/logout`)
        .then((data) => {
          // console.log('result: ', data);
          resolve(data);
        })
        .catch((reason) => {
          // console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  getMyInfo(): Promise<UserModel> {
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`/api/v1/user/me`, { ignoreAlertModal: true })
        .then((data: any) => {
          //console.log('result: ', data);
          if (data.user != null) {
            resolve(data.user as UserModel);
          } else {
            reject({ message: "not found item" });
          }
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }

  updateMobileDeviceInfo(params: {
    crudType: string;
    deviceToken: string;
    timezone: string | null;
    locale: string | null;
    manufacturer: string | null;
    model: string | null;
    product: string | null;
    hardware: string | null;
    os: string | null;
    versionSdk: number | null;
    versionRelease: string | null;
    packageName: string | null;
    versionCode: number | null;
    versionName: string | null;
  }) {
    const strParam = JSON.stringify(params);
    return new Promise((resolve, reject) => {
      core.http
        .postJson(`/api/v1/user/me/mobile-device`, strParam)
        .then((data) => {
          //console.log('result: ', data);
          resolve(data);
        })
        .catch((reason) => {
          //console.log('error: ', reason);
          reject(reason);
        });
    });
  }
}

export default new UserService();
