
import mixins from "vue-typed-mixins";
import AppBarProps from "@/components/core/AppBarProps.vue";
import core from "@/core";
import store from "@/store";
import { ApprovalType } from "@/types";
import CalendarUtils from "@/utils/calendar-utils";

export default mixins(AppBarProps).extend({
  name: "AppBar",
  data: () => ({
    showMenu: false,
    companyId: 0,
    menuList: [],
    app: store.state.app,
    auth: store.state.auth,
    menuItems: {
      selected: null as any,
      list: [] as any,
    },
  }),
  computed: {
    title() {
      return this.$store.getters["topToolbar/title"];
    },
    dark() {
      return this.$store.getters["topToolbar/dark"];
    },
    clazz() {
      return this.$store.getters["topToolbar/clazz"];
    },
    elevation() {
      return this.$store.getters["topToolbar/elevation"];
    },
    color() {
      return this.$store.getters["topToolbar/color"];
    },
  },
  mounted() {
    this.$nextTick(async () => {
      this.prop.closeNavigation = false;
      if (this.app.isMobile) {
        this.showMenu = false;
      }
      //console.log("calendarList : ", this.calendarList);
    });
  },
  watch: {
    "prop.closeNavigation"(closeNavigation) {
      if (closeNavigation && this.app.isMobile) {
        this.showMenu = false;
      }
      this.prop.closeNavigation = false;
    },
  },
  methods: {
    typeToString(type) {
      return CalendarUtils.typeToString(type);
    },
    approvalToHtml(company) {
      if (company != null) {
        if (company.lastApproval.type == ApprovalType.ALLOW) {
          return "";
        } else if (company.lastApproval.type == ApprovalType.DENY) {
          return "<span class='red--text'>(거절)</span>";
        } else if (company.lastApproval.type == ApprovalType.REQUEST) {
          return "(승인 요청중)";
        } else {
          return "(알 수 없음)";
        }
      }
      return "";
    },
    toggleMenu() {
      if (this.showMenu == null) this.showMenu = true;
      this.showMenu = !this.showMenu;
    },
    goBack() {
      this.$router.back();
    },
    async logout() {
      const result = await core.alert.show({
        title: "확인",
        body: "현재 인증된 정보가 해제됩니다.<br>계속 진행하시겠습니까?",
        showCancelButton: true,
      });
      if (result === "confirm") {
        await this.$router.push("/logout");
      }
    },
  },
});
