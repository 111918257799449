import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import NotSupportBrowser from "@/views/errors/NotSupportBrowser.vue";
import NotFound from "@/views/errors/NotFound.vue";
import Home from "@/views/Home.vue";
import Logout from "@/views/Logout.vue";
import core from "@/core";
import store from "@/store";
import Login from "@/views/Login.vue";
import Constant from "@/store/constant";
import { UserModel } from "@/models/user/user.model";
import Estimate from "@/views/Estimate.vue";
import EstimatePrint from "@/views/EstimatePrint.vue";
import ConstructionStatus from "@/views/ConstructionStatus.vue";
import EstimatePayment from "@/views/EstimatePayment.vue";
import ConstructionStatusView from "@/views/ConstructionStatusView.vue";

Vue.use(VueRouter);

const appName = Constant.appName;

export enum MenuType {
  NAVIGATION = "navigation",
  BACK = "back",
  TITLE = "title",
  NONE = "none",
  BOTTOMBAR = "bottombar",
  CALENDAR = "calendar",
}

export interface SubMenu {
  text: SubTextMenu | null | undefined;
  icon: SubIconMenu | null | undefined;
}

export interface SubIconMenu {
  visible: boolean;
  list: Menu[] | null;
}

export interface SubTextMenu {
  visible: boolean;
  width: number;
  list: Menu[] | null;
}

export interface Menu {
  icon: string;
  text: string;
  id: string;
  visible: boolean;
  event: boolean;
}

export interface RouteMeta {
  title: string;
  roles: string[] | null;
  menuType: MenuType;
  pageStack: boolean;
  subMenu: SubMenu | null;
  scrollable: boolean;
}

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "home",
    component: Home,
    meta: {
      title: "홈",
      roles: ["customer"],
      menuType: MenuType.NONE,
      pageStack: false,
      subMenu: {
        icon: {
          visible: true,
          list: [
            {
              icon: "mdi-chevron-right",
              text: "업체",
              id: "company",
              visible: true,
              event: false,
            },
          ],
        },
        text: null,
      },
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/estimate",
    name: "estimate",
    component: Estimate,
    meta: {
      title: "견적서",
      roles: null,
      menuType: MenuType.BACK,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/estimate/payment",
    name: "estimatePayment",
    component: EstimatePayment,
    meta: {
      title: "결제",
      roles: null,
      menuType: MenuType.BACK,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/estimate/print",
    name: "estimatePrint",
    component: EstimatePrint,
    meta: {
      title: "견적서",
      roles: null,
      menuType: MenuType.NONE,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/construction-status",
    name: "constructionStatus",
    component: ConstructionStatus,
    meta: {
      title: "시공현황",
      roles: null,
      menuType: MenuType.BACK,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/construction-status/:id(\\d+)",
    name: "constructionStatusView",
    component: ConstructionStatusView,
    meta: {
      title: "시공현황",
      roles: null,
      menuType: MenuType.BACK,
      pageStack: false,
      subMenu: {
        icon: {
          visible: true,
          list: [
            {
              icon: "mdi-download",
              text: "다운로드",
              id: "download",
              visible: true,
              event: false,
            },
          ],
        },
        text: null,
      },
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      title: "로그인",
      roles: null,
      menuType: MenuType.NONE,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
    meta: {
      title: "로그아웃",
      roles: ["customer"],
      menuType: MenuType.NONE,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "/error/not-support-browser",
    name: "notSupportBrowser",
    component: NotSupportBrowser,
    meta: {
      title: "",
      roles: null,
      menuType: MenuType.NONE,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
  {
    path: "*",
    name: "notFound",
    component: NotFound,
    meta: {
      title: "",
      roles: null,
      menuType: MenuType.NONE,
      pageStack: false,
      subMenu: null,
      scrollable: true,
    } as RouteMeta,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const routeMap = new Map();
routes.forEach((data) => {
  if (data.children != null) {
    const path = data.path;
    data.children.forEach((data) => {
      routeMap.set(path + data.path, data);
    });
  } else {
    routeMap.set(data.path, data);
  }
});

async function routerResult(next, path: string) {
  next({ path: path });
  const route = routeMap.get(path);
  // console.log("routerResult path : ", path);
  if (route) {
    return route.meta;
  }
  return "";
}

async function beforeEach(to, from, next) {
  const user = (await store.getters["auth/user"]()) as UserModel | null;
  if (user) {
    // 로그인 중인 사용자
    if (to.meta.roles != null) {
      // 로그인 사용자 접근 가능 페이지일 경우 이동
      next();
      // console.log("로그인 사용자 next");
    } else {
      // 비로그인 사용자 접근 가능 페이지일 경우 이동
      if (to.path.toLowerCase() === "/login") {
        if (core.utils.validate.isBlank(to.query.v)) {
          // 로그인중인 사용자가 로그인 페이지 접근시 이동
          return await routerResult(next, "/home");
        } else {
          // 로그인중인 사용자가 자동인증 정보파라미터를 포함해서 들어왔을 경우 로그인 페이지로 이동
          next();
        }
      } else {
        next();
      }
      // console.log("로그인 사용자 next, 페이지 권한 필요없음");
    }
  } else {
    if (to.meta.roles != null) {
      // 로그인 사용자 접근 가능 페이지일 경우 메인 페이지로 이동
      return await routerResult(next, "/login");
    } else {
      next();
      // console.log("비로그인 사용자 next, 페이지 권한 필요없음");
    }
  }

  return to.meta;
}

let first = true;

router.beforeEach(async (to, from, next) => {
  // console.log("from   : ", from);
  // console.log("to     : ", to);
  //console.log("from " + from.path + " -> to : " + to.path);

  if (store.state.app.printClass) {
    await store.dispatch("app/clearPrint");
  }

  if (store.state.topToolbar.changedStyle) {
    await store.dispatch("topToolbar/changeOrgStyle");
  }

  if (core.toast.isShow()) {
    core.toast.hide();
  }

  if (
    (from.path === "/home" && to.path === "/login") ||
    (from.path === "/login" && to.path === "/login")
  ) {
    window.history.back();
    return;
  }

  if (first) {
    first = false;
    if (core.utils.redirectWebviewBrowser(router)) {
      return;
    }
  }

  const meta = await beforeEach(to, from, next);
  if (meta != null) {
    if (meta.title != null) {
      if (meta.title.length > 0) {
        document.title = `${appName} - ${meta.title}`;
      } else {
        document.title = appName;
      }
    }

    if (meta.menuType !== MenuType.NONE) {
      await store.dispatch("topToolbar/changeTitle", { title: meta.title });
    }

    if (!meta.scrollable) {
      document.documentElement.classList.add("overflow-y-hidden");
    } else {
      document.documentElement.classList.remove("overflow-y-hidden");
    }
  }
});

export default router;
