
import mixins from "vue-typed-mixins";
import MixinsPageForm from "@/mixins/page-form";
import ConstructionStatusComponent from "@/components/constructino-status/ConstructionStatusComponent.vue";
import CustomerService from "@/services/customer/customer.service";
import { ConstructionStatusModel } from "@/models/construction-status/construction-status.model";
import { Menu } from "@/router";
import core from "@/core";
import Constant from "@/store/constant";

export default mixins(MixinsPageForm).extend({
  name: "ConstructionStatusView",
  components: { ConstructionStatusComponent },
  data: () => ({
    $viewer: null as any,
    viewerOptions: Constant.viewerOptions,
    status: null as ConstructionStatusModel | null,
    iconSubMenu: {
      download: null as Menu | null,
    },
  }),
  mounted() {
    this.$nextTick(async () => {
      const params = this.$route.params;
      if (params.id == null || isNaN(Number(params.id))) {
        await this.notFound();
        return;
      }
      const id = Number(params.id);
      try {
        const status = (this.status = (await CustomerService.getConstructionStatus(
          id
        )) as ConstructionStatusModel);
        // console.log("status : ", status);
        await this.$store.dispatch("topToolbar/changeTitle", {
          title: status.title,
          ignoreCheck: true,
        });
      } catch (e) {
        console.log(e);
        await this.notFound();
        return;
      }
    });
  },
  watch: {
    "iconSubMenu.download.event"(event) {
      this.iconSubMenu.download.event = false;

      if (event) {
        this.downloadImage();
      }
    },
  },
  methods: {
    inited(viewer) {
      this.$viewer = viewer;
    },
    imageHeight(item) {
      if (item.imageWidth && item.imageHeight) {
        const size = this.app.size;
        const cal = (size.width - 10) / item.imageWidth;
        return item.imageHeight * cal;
      }
    },
    onView(detail) {
      const imageUri = detail.imageUri;
      let selected = null as any;
      this.$viewer.images.some((el) => {
        if (el.src.indexOf(imageUri) > -1) {
          selected = el;
        }
      });
      if (selected != null) {
        selected.click();
      } else {
        this.$viewer.show();
      }
      // window.open(detail.imageUri, "_blank");
    },
    async downloadImage() {
      try {
        if (this.status) {
          const downloadUri = (await CustomerService.getCustomerStatusImageFile(this.status.id)) as any;
          //console.log("response : ", response);
          core.loader.show("다운로드중...");
          await core.http.downloadFile(downloadUri);
        }
      } catch (e) {
        console.log(e);
      }
      core.loader.hide();
    }
  },
});
