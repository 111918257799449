var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-app',[(
      _vm.prop.menuType === 'navigation' ||
      _vm.prop.menuType === 'back' ||
      _vm.prop.menuType === 'title' ||
      _vm.prop.menuType === 'bottombar' ||
      _vm.prop.menuType === 'calendar'
    )?_c('app-bar',{attrs:{"prop":_vm.prop}}):_vm._e(),_c('vue-page-stack',[_c('router-view',{key:_vm.$route.fullPath})],1),(_vm.prop.menuType === 'bottombar' || _vm.prop.menuType === 'calendar')?_c('app-bottom-bar',{attrs:{"bottomNav":_vm.bottomNav},on:{"update:bottomNav":function($event){_vm.bottomNav=$event},"update:bottom-nav":function($event){_vm.bottomNav=$event}}}):_vm._e(),(_vm.app.modal.alert.visible)?_c('alert-modal',{attrs:{"properties":_vm.app.modal.alert}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }