// Polyfills
import "core-js/stable";
import "regenerator-runtime/runtime";

import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VuePageStack from "@/plugins/vue-page-stack";

import { extend, localize } from "vee-validate";
import { email, max, min, required } from "vee-validate/dist/rules";
import core from "@/core";
import TopToolbar from "@/components/core/TopToolbar.vue";
import SelectSearchModal from "@/modals/core/SelectSearchModal.vue";
import SelectCameraModal from "@/modals/core/SelectCameraModal.vue";

import Constant from "@/store/constant";
import ComponentTemplate from "@/components/core/ComponentTemplate.vue";

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

import "viewerjs/dist/viewer.css";
import Viewer from "v-viewer";

Vue.config.productionTip = false;

const options = {
  timeout: false,
  maxToasts: 1,
};

Vue.use(Toast, options);

Vue.use(Viewer);

// 공통 유틸 초기화
core.init(store, router, Vue.$toast);

Vue.component("top-toolbar", TopToolbar);

// 아이템 검색 및 선택 모달
Vue.component("select-search-modal", SelectSearchModal);

// 카메라 선택 모달
Vue.component("select-camera-modal", SelectCameraModal);

Vue.component("scc-component-template", ComponentTemplate);

if (!("toJSON" in Error.prototype))
  Object.defineProperty(Error.prototype, "toJSON", {
    value: function () {
      const alt = {};

      Object.getOwnPropertyNames(this).forEach(function (key) {
        try {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          alt[key] = this[key];
        } catch (e) {
          alt[key] = e;
        }
      }, this);

      return alt;
    },
    configurable: true,
    writable: true,
  });

Vue.config.errorHandler = (error, vm, info) => {
  console.log("error : ", error);
  core.http.sendErrorLog(error);
};

Vue.prototype.$eventBus = new Vue();

// page stack(이전 페이지 저장 처리)
Vue.use(VuePageStack, {
  router,
  routerBeforeTask: store.state.app.routerBeforeTask,
  name: "vue-page-stack",
  keyName: "stackKey",
});

// validate
extend("required", required);
extend("min", min);
extend("max", max);
extend("email", email);
extend("phone", {
  validate(value) {
    return core.utils.validate.phone(value);
  },
  message: "휴대전화 형식이 올바르지 않습니다",
});
extend("password", {
  params: ["target"],
  validate(value, params) {
    const target = (params as any).target;
    // console.log('value : ', value, ', target : ', target);
    return value === target;
  },
  message: "비밀번호 확인이 일치하지 않습니다",
});

localize("ko", {
  messages: Constant.validate.language.korea.messages,
  names: {
    user_customerPhone: "고객 휴대전화 ",
    user_authCode: "인증코드 ",
    user_username: "아이디 ",
    user_password: "비밀번호 ",
    user_confirmPassword: "비밀번호 확인  ",
    user_email: "이메일 ",
    user_name: "이름 ",
    user_phone: "휴대전화 ",
    user_certificationNumber: "인증번호 ",
    user_currentPassword: "현재 비밀번호 ",
    company_role: "업체권한 ",
    estimate_account: "거래처 ",
    estimate_address: "주소 ",
    estimate_customerPhone: "휴대전화 ",
    estimate_type: "타입 ",
    estimate_dong: "동 ",
    estimate_ho: "호 ",
    estimate_customerName: "고객명 ",
    estimate_description: "품명 ",
    estimate_specification: "규격 ",
    estimate_quantity: "수량 ",
    estimate_unitprice: "단가 ",
    estimate_workScope: "작업범위 ",
    estimate_date: "작업일정 ",
    estimate_price: "금액 ",
    estimate_discount: "할인금액 ",
    estimate_remark: "비고 ",
    estimate_scheduleYn: "일정상태 ",
    estimate_contract: "계약상태 ",
    estimate_paymentYn: "결제상태 ",
    estimate_paymentBankAccount: "결제계좌 ",
    estimate_payment_type: "종류 ",
    estimate_payment_price: "금액 ",
    estimate_payment_date: "결제일 ",
    unitPriceTable_name: "단가표 이름 ",
    category: "품목 ",
    company: "업체 ",
    company_name: "업체명 ",
    company_ceoName: "대표명 ",
    company_phone: "대표번호 ",
    company_bankName: "은행명 ",
    company_bankAccount: "계좌번호 ",
    company_accountHolder: "예금주 ",
    company_estimateContentTitle: "제목 ",
    company_estimateContentBody: "내용 ",
    calendar_event_calendar: "캘린더 ",
    calendar_event_title: "제목 ",
    calendar_event_color: "색상 ",
    calendar_type: "종류 ",
    calendar_name: "이름 ",
    calendar_memo: "설명 ",
    workscope: "작업범위 ",
    memo: "메모 ",
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
