
import mixins from "vue-typed-mixins";
import MixinsPage from "@/mixins/page";
import EstimatePaymentService from "@/services/estimate/estimate-payment.service";
import { EstimateModel, PaymentModel } from "@/models/estimate/estimate.model";
import core from "@/core";
import CustomerService from "@/services/customer/customer.service";

export default mixins(MixinsPage).extend({
  name: "EstimatePayment",
  components: {},
  data: () => ({
    ready: false,
    estimate: {} as EstimateModel,
    estimateUpdatedAt: null as Date | null,
    paymentList: [] as PaymentModel[],
  }),

  mounted() {
    this.$nextTick(async () => {
      try {
        const estimate = (this.estimate = (await CustomerService.getEstimate()) as EstimateModel);
        const paymentList = (this.paymentList = (await CustomerService.getEstimatePaymentList()) as PaymentModel[]);
        this.sortPaymentList();
      } catch (e) {
        console.log(e);
        await this.notFound();
        return;
      }

      this.ready = true;
    });
  },
  activated() {},
  watch: {},
  methods: {
    sortPaymentList() {
      this.paymentList.sort((a, b) => {
        const aMoment = core.date.instance(a.date);
        const bMoment = core.date.instance(b.date);
        if (aMoment.isBefore(bMoment)) return -1;
        else if (bMoment.isBefore(aMoment)) return 1;
        return 0;
      });
    },
    typeToText(item: PaymentModel) {
      return EstimatePaymentService.typeToText(item);
    },
  },
});
