import UserService from "@/services/user/user.service";
import core from "@/core";
import { AuthState, MobileSystemProperties, RootState } from "@/types";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import store from "@/store";
import { UserModel } from "@/models/user/user.model";
import _ from "lodash";

const state: AuthState = {
  user: null,
  sessionCheckTime: null,
  mobileToken: null,
  systemProperties: null,
  changedCompanyDate: new Date(),
};

export const getters: GetterTree<AuthState, RootState> = {
  user: (theState: AuthState) => {
    return async (reload: boolean | null): Promise<UserModel | null> => {
      if (reload == null) reload = false;

      const curTime = new Date();
      if (theState.sessionCheckTime == null) {
        theState.sessionCheckTime = new Date(0);
      }

      if (!reload) {
        //console.log("call getUser");
        const elapsedTimeMillis = curTime.getTime() - theState.sessionCheckTime.getTime();
        //console.log(elapsedTimeMillis);
        if (elapsedTimeMillis < 300000) return theState.user;
      }

      try {
        theState.sessionCheckTime = curTime;
        const user = await UserService.getMyInfo();
        // if (user.profile == null) {
        //   user.profile = {
        //     id: null,
        //     uri: "/img/user/profile.png",
        //     thumbnailUri: "/img/user/profile.png",
        //     width: 64,
        //     height: 64,
        //   };
        // }
        if (user.roles.indexOf("CUSTOMER") > -1) {
          mutations["login"](theState, user);
          return user;
        } else {
          console.log("is not support role : ", user.roles);
          await UserService.logout();
        }
      } catch (reason) {
        if (reason === 401) {
          // await store.dispatch("auth/logout");
        }
      }
      return null;
    };
  },
};

export const actions: ActionTree<AuthState, RootState> = {
  async login({ commit }, params) {
    const result = await UserService.login(params);
    state.sessionCheckTime = new Date(0);

    const user = await store.getters["auth/user"](true);
    //console.log("user : ", user);
    if (user != null) {
      return user;
    }
    await core.alert.show({
      title: "알림",
      body: "아이디 또는 비밀번호가 올바르지 않습니다(1)",
      confirmButtonText: "확인",
    });
  },

  async logout({ commit }) {
    if (state.user != null) {
      try {
        // delete mobile device info
        //console.log("state.systemProperties : ", state.systemProperties);
        if (state.systemProperties != null) {
          const params = {
            crudType: "D",
            deviceToken: state.systemProperties?.deviceToken,
          } as any;
          await UserService.updateMobileDeviceInfo(params);
        }

        return await UserService.logout();
      } catch (e) {
        console.log(e);
      } finally {
        commit("logout");
      }
    }
  },

  async updateMobileSystemProperties({ commit }, systemProperties: MobileSystemProperties) {
    state.mobileToken = systemProperties.deviceToken;
    if (state.user != null) {
      // delete mobile device info
      const params = _.cloneDeep(systemProperties) as any;
      params.crudType = "C";
      await UserService.updateMobileDeviceInfo(params);

      commit("mobileSystemProperties", systemProperties);
    }
  },
};

const mutations: MutationTree<AuthState> = {
  async login(theState, user: UserModel) {
    // console.log("mutations[login]: ", theState, user);
    //console.log("update user info");
    const preUser = state.user;
    theState.sessionCheckTime = new Date();
    theState.user = user;
    theState.changedCompanyDate = new Date();

    // core.webSocket.init();

    //console.log("state.mobileToken : ", state.mobileToken);
    if (state.mobileToken) {
      const params = { deviceToken: state.mobileToken, crudType: "C" } as any;
      await UserService.updateMobileDeviceInfo(params);
      const systemProperties = { deviceToken: params.deviceToken } as MobileSystemProperties;
      mutations.mobileSystemProperties(state, systemProperties);
    }
  },
  logout(theState) {
    //console.log('mutations[logout]: ', state.user);
    if (theState.user != null) {
      //console.log("logout!");
      theState.sessionCheckTime = new Date();
      theState.user = null;
      // core.webSocket.disconnect();
    }
  },

  mobileSystemProperties(state, systemProperties: MobileSystemProperties | null) {
    //console.log('mutations[logout]: ', state.user);
    state.systemProperties = systemProperties;
    if (systemProperties != null) {
      console.log("update system properties : ", JSON.stringify(systemProperties));
    }
  },
};

export const auth: Module<AuthState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
