
import mixins from "vue-typed-mixins";
import MixinsComponent from "@/mixins/component";
import CustomerService from "@/services/customer/customer.service";
import core from "@/core";
import { ConstructionStatusModel } from "@/models/construction-status/construction-status.model";

export default mixins(MixinsComponent).extend({
  name: "ConstructionStatusComponent",
  data: () => ({
    itemList: [] as ConstructionStatusModel[],
  }),
  mounted() {
    this.$nextTick(async () => {
      try {
        this.itemList =
          (await CustomerService.getConstructionStatusList()) as ConstructionStatusModel[];
        // console.log("itemList : ", this.itemList);
      } catch (e) {
        console.log(e);
      }
    });
  },
  methods: {
    fileSizeFormat(value: string) {
      return core.utils.format.filesize(true, parseInt(value), 2);
    },
  },
});
