import AbstractTableService from "@/services/core/abstract-table.service";
import core from "@/core";

class CustomerService extends AbstractTableService {
  constructor() {
    super("/api/v1/estimate", "estimate");
  }

  getEstimate(): Promise<unknown> {
    const url = "/api/v1/customer/estimate";
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`${url}`, null, null)
        .then((data) => {
          if (typeof data === "object") {
            if (data.estimate) {
              resolve(data.estimate);
            } else {
              reject({ message: "not found item" });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  exportEstimate() {
    const url = "/api/v1/customer/estimate/export";
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`${url}`)
        .then((data) => {
          resolve(data);
        })
        .catch((reason: any) => {
          reject(reason);
        });
    });
  }

  getCalendarEventList(): Promise<unknown> {
    const url = "/api/v1/customer/calendar-event";
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`${url}`, null, null)
        .then((data) => {
          if (typeof data === "object") {
            if (data.eventList) {
              resolve(data.eventList);
            } else {
              reject({ message: "not found item" });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  getEstimatePaymentList(): Promise<unknown> {
    const url = "/api/v1/customer/estimate/payment";
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`${url}`, null, null)
        .then((data) => {
          if (typeof data === "object") {
            if (data.paymentList) {
              resolve(data.paymentList);
            } else {
              reject({ message: "not found item" });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  getCustomerStatusImageFile(id: number) {
    const url = "/api/v1/customer/construction-status/" + id + "/image-file";
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`${url}`, null, null)
        .then((data) => {
          if (typeof data === "object") {
            if (data.downloadUri) {
              resolve(data.downloadUri);
            } else {
              reject({ message: "not found item" });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  getConstructionStatus(id: number) {
    const url = "/api/v1/customer/construction-status/" + id;
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`${url}`, null, null)
        .then((data) => {
          if (typeof data === "object") {
            if (data.constructionStatus) {
              resolve(data.constructionStatus);
            } else {
              reject({ message: "not found item" });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }

  getConstructionStatusList() {
    const url = "/api/v1/customer/construction-status";
    return new Promise((resolve, reject) => {
      core.http
        .getJson(`${url}`, null, null)
        .then((data) => {
          if (typeof data === "object") {
            if (data.constructionStatusList) {
              resolve(data.constructionStatusList);
            } else {
              reject({ message: "not found item" });
            }
          } else {
            reject({
              message: "data type error",
            });
          }
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  }
}

export default new CustomerService();
